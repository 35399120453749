/**
 * Created by Zhoujl on 2019/8/29.
 */
import moment from 'moment';

export default class DateTimeUtils {
  //UTC日期字符转Date
  static utcString2Date(dateString) {
    let time = moment(dateString).toString();
    return DateTimeUtils.string2Date(time);
  }

  //日期字符转Date
  static string2Date(dateString) {
    if (dateString) {
      let regEx = new RegExp('\\-', 'gi');
      let validDateStr = dateString.replace(regEx, '/');
      let milliseconds = Date.parse(validDateStr);
      return new Date(milliseconds);
    }
  }

  //日期字符串转 指定格式日期字符串
  static string2FormatString(dateString, formater) {
    let date = DateTimeUtils.string2Date(dateString);
    return DateTimeUtils.formatDate(date.getTime(), formater);
  }

  // timestamp时间戳  formater时间格式
  static formatDate(timestamp, formater) {
    let formaterNew = formater;
    let date = new Date();
    date.setTime(parseInt(timestamp, 10));
    formaterNew = formaterNew !== null ? formaterNew : 'yyyy-MM-dd hh:mm';

    let o = {
      'M+': date.getMonth() + 1, //月
      'd+': date.getDate(), //日
      'h+': date.getHours(), //小时
      'm+': date.getMinutes(), //分
      's+': date.getSeconds(), //秒
      'q+': Math.floor((date.getMonth() + 3) / 3), //季度
      S: date.getMilliseconds() //毫秒
    };

    if (/(y+)/.test(formaterNew)) {
      formaterNew = formaterNew.replace(RegExp.$1, (date.getFullYear() + '').substr(4 - RegExp.$1.length));
    }
    for (let k in o) {
      if (new RegExp('(' + k + ')').test(formaterNew)) {
        formaterNew = formaterNew.replace(RegExp.$1, RegExp.$1.length === 1 ? o[k] : ('00' + o[k]).substr(('' + o[k]).length));
      }
    }
    return formaterNew;
  }

  // 获取day天之前的日期
  static getBeforeDayDate(day) {
    let date = new Date();
    let timestamp = date.getTime();
    return new Date(timestamp - day * 24 * 3600 * 1000);
  }

  // 获取day天之后的日期
  static getAfterDayDate(day) {
    let date = new Date();
    let timestamp = date.getTime();
    return new Date(timestamp + day * 24 * 3600 * 1000);
  }

  //获取本月第一天
  static getCurrentMonthFirst() {
    let date = new Date();
    date.setDate(1);
    return date;
  }

  //获取本月最后一天
  static getCurrentMonthLast() {
    let date = new Date();
    let currentMonth = date.getMonth();
    let nextMonth = ++currentMonth;
    let nextMonthFirstDay = new Date(date.getFullYear(), nextMonth, 1);
    let oneDay = 1000 * 60 * 60 * 24;
    return new Date(nextMonthFirstDay - oneDay);
  }

  //查询条件 本月 的时间段
  static getCurrentMonthStartAndEndTime() {
    let start = DateTimeUtils.getCurrentMonthFirst();
    let end = DateTimeUtils.getCurrentMonthLast();
    let startTime = start.getFullYear() + '-' + (start.getMonth() + 1) + '-' + start.getDate();
    let endTime = end.getFullYear() + '-' + (end.getMonth() + 1) + '-' + end.getDate();
    return [startTime, endTime];
  }

  //查询条件 今天  前三天 前七天 等时间段
  static getBeforeDayStartAndEndTime(day, formater = 'yyyy-MM-dd') {
    let date = new Date();
    let beforeDayDate = DateTimeUtils.getBeforeDayDate(day);
    let startTime = DateTimeUtils.formatDate(beforeDayDate.getTime(), formater);
    let endTime = DateTimeUtils.formatDate(date.getTime(), formater);
    return [startTime, endTime];
  }

  //输入分钟获取00:00格式时长
  static getHourTimeMinTime(value) {
    //处理小时
    let hour = Math.floor(value / 60);
    let hourTime = '';
    if (hour >= 0 && hour < 10) {
      hourTime = '0' + hour; // 1 --> 01
    } else if (hour >= 10 && hour < 24) {
      hourTime = '' + hour;
    } else if (hour >= 24) {
      hourTime = '0' + (hour - 24);
    }
    //处理分钟
    let min = Math.floor(value % 60);
    let minTime = '';
    if (min >= 0 && min < 10) {
      minTime = '0' + min; // 1 --> 01
    } else {
      minTime = '' + min;
    }
    return hourTime + ':' + minTime;
  }
}
