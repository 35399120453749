import menu from './menu';
import store from './vuex/store';
import StorageUtils from './utils/StorageUtils';

export default class MenuUtils {
  /**
   * 跳转页面并刷新菜单
   * @param path
   * @param query
   * @param blank
   */
  static routerQueryTo(path, query = {}, blank = false) {
    let router = window['router'];
    if (blank) {
      const target = router.resolve({ path: path, query: query });
      window.open(target.href, '_blank');
    } else {
      router.push({ path: path, query: query });
      MenuUtils.menuRefresh(path);
    }
  }

  /**
   * 跳转页面并刷新菜单
   * @param path
   * @param params
   * @param blank
   */
  static routerParamsTo(path, params = {}, blank = false) {
    let router = window['router'];
    if (blank) {
      const target = router.resolve({ path: path, params: params });
      window.open(target.href, '_blank');
    } else {
      router.push({ path: path, params: params });
      MenuUtils.menuRefresh(path);
    }
  }

  /**
   * 根据路由刷新重置菜单选中状态
   * @param path
   */
  static menuRefresh(path) {
    for (let i = 0, len = menu.length; i < len; i++) {
      let item = menu[i];
      if (item.route == path) {
        setTimeout(() => {
          MenuUtils.menuSelectByPath(path);
        }, 200);
        break;
      }
    }
  }

  /**
   * 根据路由获取一级菜单
   */
  static menuParentGetByPath(path) {
    for (let i = 0, len = menu.length; i < len; i++) {
      let item = menu[i];
      if (item.route == path) {
        if (item.parentId == null || item.parentId == '') {
          return item;
        }
        return MenuUtils.menuParentGetById(item.parentId);
      }
    }
  }

  /**
   * 根据id获取一级菜单
   */
  static menuParentGetById(id) {
    for (let i = 0, len = menu.length; i < len; i++) {
      let item = menu[i];
      if (item.id == id) {
        if (item.parentId == null || item.parentId == '') {
          return item;
        }
        return MenuUtils.menuParentGetById(item.parentId);
      }
    }
  }

  /**
   * 根据当前路由选中菜单
   * @param path
   */
  static menuSelectByPath(path) {
    for (let i = 0, len = menu.length; i < len; i++) {
      let item = menu[i];
      if (item.route == path) {
        MenuUtils.menuSelectById(item.id);
        break;
      }
    }
  }

  /**
   * 根据id选中菜单 此方法谨慎使用
   * 严禁使用魔法值 例如：MenuUtils.menuSelectById(1)
   * menu文件一修改  上面这个写法就会全部出错
   * 正确用法：MenuUtils.menuSelectById(menu.id) 或使用 MenuUtils.menuSelectByPath('xx/xx')
   * @param id
   */
  static menuSelectById(id) {
    $.zhoujl.menuActive(id);
  }

  static backPage() {
    let router = window['router'];
    router.go(-1);
  }
}
