(function ($) {
    if ($.zhoujl == undefined) {
        $.zhoujl = {};
    }

    var init=false;
    //Layout初始化
    $.zhoujl.layoutInit = function () {
        $.zhoujl.navDropdown();
        $.zhoujl.controlSidebarInit();
        $.zhoujl.mainMenuInit();
        $.zhoujl.pushMenuInit();
        $('body, html, .wrapper').css('height', 'auto');
        if(init==false){
              $(window, ".wrapper").resize(function () {
                  $.zhoujl.resizeLayout();
              });
              //IE8兼容性初始化
              if (navigator.appName == "Microsoft Internet Explorer" && navigator.appVersion.split(";")[1].replace(/[ ]/g, "") == "MSIE8.0") {
                IE8Compatibility();
              }
              init=true;
        }
        $.zhoujl.resizeLayout();
    };
    //系统IE8兼容修复
    function IE8Compatibility() {
      addEventListener = function (n, f) {
        if ("on" + n in this.constructor.prototype)
          this.attachEvent("on" + n, f);
        else {
          var o = this.customEvents = this.customEvents || {};
          n in o ? o[n].push(f) : (o[n] = [f]);
        };
      };
      removeEventListener = function (n, f) {
        if ("on" + n in this.constructor.prototype)
          this.detachEvent("on" + n, f);
        else {
          var s = this.customEvents && this.customEvents[n];
          if (s) for (var i = 0; i < s.length; i++)
            if (s[i] == f) return void s.splice(i, 1);
        };
      };
      dispatchEvent = function (e) {
        if ("on" + e.type in this.constructor.prototype)
          this.fireEvent("on" + e.type, e);
        else {
          var s = this.customEvents && this.customEvents[e.type];
          if (s) for (var s = s.slice(0), i = 0; i < s.length; i++)
            s[i].call(this, e);
        }
      };
      //为document对象添加
      HTMLDocument.prototype.addEventListener = addEventListener;
      HTMLDocument.prototype.removeEventListener = removeEventListener;
      HTMLDocument.prototype.dispatchEvent = dispatchEvent;
      HTMLDocument.prototype.createEvent = function () {
        var e = document.createEventObject();
        e.initMouseEvent = function (en) { this.type = en; };
        e.initEvent = function (en) { this.type = en; };
        return e;
      };
      //为全元素添加
      var tags = [
        "Unknown", "UList", "Title", "TextArea", "TableSection", "TableRow",
        "Table", "TableCol", "TableCell", "TableCaption", "Style", "Span",
        "Select", "Script", "Param", "Paragraph", "Option", "Object", "OList",
        "Meta", "Marquee", "Map", "Link", "Legend", "Label", "LI", "Input",
        "Image", "IFrame", "Html", "Heading", "Head", "HR", "FrameSet",
        "Frame", "Form", "Font", "FieldSet", "Embed", "Div", "DList",
        "Button", "Body", "Base", "BR", "Area", "Anchor"
      ], html5tags = [
        "abbr", "article", "aside", "audio", "canvas", "datalist", "details",
        "dialog", "eventsource", "figure", "footer", "header", "hgroup", "mark",
        "menu", "meter", "nav", "output", "progress", "section", "time", "video"
      ], properties = {
        addEventListener: { value: addEventListener },
        removeEventListener: { value: removeEventListener },
        dispatchEvent: { value: dispatchEvent }
      };
      for (var o, n, i = 0; o = window["HTML" + tags[i] + "Element"]; i++)
        tags[i] = o.prototype;
      for (i = 0; i < html5tags.length; i++)
        tags.push(document.createElement(html5tags[i]).constructor.prototype);
      for (i = 0; o = tags[i]; i++)
        for (n in properties) Object.defineProperty(o, n, properties[n]);
  }

    //Layout刷新：页面或屏幕大小发生变化时所需的刷新
    $.zhoujl.resizeLayout=function() {
        var window_height = $(window).outerHeight();                     //屏幕当前高
        var header_height = $("#main-header").outerHeight() || 0;        //顶部导航栏高
        var footer_height = $("#main-footer").outerHeight() || 0;
        var breadcrumb=$(".breadcrumbview").length==0?0:50;
        //重设某些内容高度
        $("#main-content").css('height', window_height - header_height - footer_height - 1-breadcrumb);
    }

    //导航按钮附加信息初始化
    $.zhoujl.navDropdown = function () {
        $("a.dropdown-toggle[data-toggle='dropdown']").unbind("click");
        $("a.dropdown-toggle[data-toggle='dropdown']").bind('click',function (obj) {
            if ($(obj).parent("li").hasClass("open") == false) {
                $(obj).parent("li").addClass("open");
            }
        });
    }

    //右侧滑动面板初始化
    $.zhoujl.controlSidebarInit = function () {
        $("a[data-toggle='control-sidebar']").unbind("click");
        $("a[data-toggle='control-sidebar']").bind('click',function () {
            if ($("#control-sidebar").hasClass("control-sidebar-open") == false) {
                $("#control-sidebar").addClass("control-sidebar-open");
            } else {
                $("#control-sidebar").removeClass("control-sidebar-open");
            }
        });
    }

    //菜单按钮伸展初始化
    $.zhoujl.mainMenuInit = function () {
        var animationSpeed = 300;//伸展速度
        $(document).off('click', '.sidebar li a')
            .on('click', '.sidebar li a', function (e) {
                var $this = $(this);
                var checkElement = $this.next();
                if ((checkElement.is('.treeview-menu')) && (checkElement.is(':visible')) && (!$('body').hasClass('sidebar-collapse'))) {
                    checkElement.slideUp(animationSpeed, function () {
                        checkElement.removeClass('menu-open');
                    });
                    checkElement.parent("li").removeClass("active");
                }else if ((checkElement.is('.treeview-menu')) && (!checkElement.is(':visible'))) {
                    var parent = $this.parents('ul').first();
                    var parent_li = $this.parent("li");
                    var ul = parent.find('ul:visible').slideUp(animationSpeed);
                    ul.removeClass('menu-open');
                    ul.parent().removeClass("active");
                  //避免同时进行收缩和展开产生闪烁
                  var time=ul.length==0?0:animationSpeed+10;
                  setTimeout(function(){
                    checkElement.slideDown(animationSpeed, function () {
                      checkElement.addClass('menu-open');
                      parent_li.addClass('active');
                    });
                  },time);
                }
                if (checkElement.is('.treeview-menu')) {
                    e.preventDefault();
                }
            });
    };

    //菜单列表侧滑伸缩初始化(屏幕不小于sm规格,即：最小宽度768)
    $.zhoujl.pushMenuInit = function () {
        $("a.sidebar-toggle[data-toggle='offcanvas']").unbind('click')
        $("a.sidebar-toggle[data-toggle='offcanvas']").bind('click',function () {
            if ($("body").hasClass('sidebar-collapse')) {
                menuExpand();
                $.cookie('menu-expand', 'yes');
            } else {
                menuCollapse();
                $.cookie('menu-expand', 'no');
            }
        });
        $("#main-sidebar").unbind('mouseover');
        $("#main-sidebar").unbind('mouseout');
        $("#main-sidebar").bind('mouseover',function() {
            if($.cookie('menu-expand')==='no'||$.cookie('menu-expand')===null) {
                menuExpand();
            }
        });
        $("#main-sidebar").bind('mouseout',function() {
            if($.cookie('menu-expand')==='no'||$.cookie('menu-expand')===null) {
                menuCollapse();
            }
        });

        if($.cookie('menu-expand')==='yes'||$.cookie('menu-expand')===null||$.cookie('menu-expand')===undefined){
            menuExpand();
        }else{
            menuCollapse();
        }
    };

    //侧滑展开菜单列表
    function menuExpand() {
        $("body").removeClass('sidebar-collapse').addClass('sidebar-expanded-on-hover');
    };

    //侧滑收缩菜单列表
    function menuCollapse() {
        if ($('body').hasClass('sidebar-expanded-on-hover')) {
            $('body').removeClass('sidebar-expanded-on-hover').addClass('sidebar-collapse');
        }
    };

    //菜单选中
    $.zhoujl.menuActive = function (menuId) {
        if (menuId) {
            $(".sidebar-menu").find("li.treeview.active").removeClass("active");
            $(".sidebar-menu").find("li").each(function (i, obj) {
                if ($(obj).data("menu-id") === menuId) {
                    $(obj).parents("li").addClass("active");
                    $(obj).parents("ul.treeview-menu").addClass("menu-open");
                    $(obj).addClass("active");
                }
            });
        }
    };

  //显示正在加载
  $.zhoujl.blockPage = function (message) {
    var html = '<div class="loading-message loading-message-boxed"><i class="fa fa-spinner fa-spin fa-fw"></i><span>&nbsp;' + (message ? message : 'Loading...') + '</span></div>';
    $.blockUI({
      message: html,
      baseZ: 5000,
      css: {
        border: '0',
        padding: '0',
        backgroundColor: 'none'
      },
      overlayCSS: {
        backgroundColor: '#555',
        opacity: 0.05,
        cursor: 'wait'
      }
    });
  };

    //隐藏加载动画
    $.zhoujl.unblockUI = function () {
      $.unblockUI();
    };
})(jQuery);
