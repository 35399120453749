const menu = [
  { id: 1, name: '首页', icon: 'fa fa-television', parentId: '', route: '/index/home', admin: true },
  { id: 2, name: '业务管理', icon: 'fa fa-star', parentId: '', route: '', admin: true },
  { id: 21, name: '检测订单', icon: '', parentId: '2', route: '/index/orders', admin: 1 },
  { id: 22, name: '报告管理', icon: '', parentId: '2', route: '/index/reportManagement', admin: 2 },
  { id: 23, name: '检测项目', icon: '', parentId: '2', route: '/index/project', admin: 3 },
  { id: 24, name: '报告类别', icon: '', parentId: '2', route: '/index/reportType', admin: 4 },
  { id: 25, name: '公司管理', icon: '', parentId: '2', route: '/index/company', admin: 5 },
  { id: 26, name: '客户管理', icon: '', parentId: '2', route: '/index/personnel', admin: 6 },
  { id: 27, name: '分成记录', icon: '', parentId: '2', route: '/index/money', admin: 7 },
  { id: 28, name: '分销比例', icon: '', parentId: '2', route: '/index/data', admin: 12 },

  { id: 5, name: '平台管理', icon: 'fa fa-star', parentId: '', route: '', admin: true },
  { id: 51, name: '轮播管理', icon: '', parentId: '5', route: '/index/banner', admin: 8 },
  { id: 52, name: '常见问题', icon: '', parentId: '5', route: '/index/faq', admin: 9 },
  { id: 54, name: '资讯管理', icon: '', parentId: '5', route: '/index/informationManagement', admin: 10 },
  { id: 55, name: '资讯类别管理', icon: '', parentId: '5', route: '/index/informationType', admin: 10 },
  { id: 56, name: '留言反馈', icon: '', parentId: '5', route: '/index/customerFeedback', admin: 11 },

  { id: 4, name: '系统管理', icon: 'fa fa-star', parentId: '', route: '', admin: 13 },
  { id: 41, name: '平台设置', icon: '', parentId: '4', route: '/index/config', admin: 13 },
  { id: 42, name: '人员管理', icon: '', parentId: '4', route: '/index/user', admin: 13 },

  { id: 61, name: '报告管理', icon: 'fa fa-star', parentId: '', route: '/index/reportCustom', admin: false },
  { id: 62, name: '产品二维码', icon: 'fa fa-star', parentId: '', route: '/index/reportQrCodeCustom', admin: false }
];
export default menu;
