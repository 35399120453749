import Index from './views/Index.vue';
import Login from './views/Login.vue';
import Home from './views/Home.vue';
import Search from './views/Search.vue';
/**
 * 参数：auth 表示相应页面是否需要判断登录
 * 参数：keepAlive 标识相应页面是否缓存
 */

let routes = [
  { path: '/login', component: Login, meta: { auth: false } },
  //重定向
  { path: '/', redirect: '/index/home', meta: { auth: false } },
  { path: '/index', redirect: '/index/home', meta: { auth: false } },
  { path: '/home', redirect: '/index/home', meta: { auth: false } },
  { path: '/search', component: Search, meta: { auth: false } },
  //赖加载
  {
    path: '/index',
    component: Index,
    meta: {
      auth: true
    },
    children: [
      //首页直接加载
      { path: 'home', component: Home, meta: { auth: false, breadcrumb: [{ name: '首页', route: '' }] } },
      { path: 'orders',name:'Orders',component: () => import('@/views/Orders.vue'), meta: { auth: false,keepAlive:true, breadcrumb: [{ name: '业务管理', route: '' },{ name: '检测订单', route: '' }] } },
      { path: 'ordersEditor', component: () => import('@/views/OrdersEditor.vue'), meta: { auth: false, breadcrumb: [{ name: '业务管理', route: '' },{ name: '订单编辑', route: '' }] } },
      { path: 'ordersDetail', component: () => import('@/views/OrdersDetail.vue'), meta: { auth: false, breadcrumb: [{ name: '业务管理', route: '' },{ name: '订单详情', route: '' }] } },
      { path: 'project',name:'Project', component: () => import('@/views/Project.vue'), meta: { auth: false,keepAlive:true, breadcrumb: [{ name: '业务管理', route: '' },{ name: '检测项目', route: '' }] } },
      { path: 'projectEditor', component: () => import('@/views/ProjectEditor.vue'), meta: { auth: false, breadcrumb: [{ name: '业务管理', route: '' },{ name: '检测项目编辑', route: '' }] } },
      { path: 'reportManagement',name:'ReportManagement', component: () => import('@/views/ReportManagement.vue'), meta: { auth: false,keepAlive:true, breadcrumb: [{ name: '业务管理', route: '' },{ name: '报告管理', route: '' }] } },
      { path: 'reportType', component: () => import('@/views/ReportType.vue'), meta: { auth: false, breadcrumb: [{ name: '业务管理', route: '' },{ name: '报告类别', route: '' }] } },
      { path: 'reportDetail', component: () => import('@/views/ReportDetail.vue'), meta: { auth: false, breadcrumb: [{ name: '业务管理', route: '' },{ name: '报告详情', route: '' }] } },
      { path: 'reportEditor', component: () => import('@/views/ReportEditor.vue'), meta: { auth: false, breadcrumb: [{ name: '业务管理', route: '' },{ name: '报告编辑', route: '' }] } },
      { path: 'reportEditor2', component: () => import('@/views/ReportEditor2.vue'), meta: { auth: false, breadcrumb: [{ name: '业务管理', route: '' },{ name: '报告上传', route: '' }] } },
      { path: 'reportCustom',name:'ReportCustom', component: () => import('@/views/ReportCustom.vue'), meta: { auth: false,keepAlive:true, breadcrumb: [{ name: '客户功能', route: '' },{ name: '报告管理', route: '' }] } },
      { path: 'reportCustomEditor', component: () => import('@/views/ReportCustomEditor.vue'), meta: { auth: false, breadcrumb: [{ name: '客户功能', route: '' },{ name: '报告编辑', route: '' }] } },
      { path: 'reportQrCodeCustom',name:'ReportQrCodeCustom', component: () => import('@/views/ReportQrCodeCustom.vue'), meta: { auth: false,keepAlive:true, breadcrumb: [{ name: '客户功能', route: '' },{ name: '产品二维码管理', route: '' }] } },
      { path: 'qrCodeEditor',name:'QrCodeEditor', component: () => import('@/views/QrCodeEditor.vue'), meta: { auth: false,keepAlive:true, breadcrumb: [{ name: '客户功能', route: '' },{ name: '产品二维码编辑', route: '' }] } },
      { path: 'reportChangeUser',name:'ReportChangeUser', component: () => import('@/views/ReportChangeUser.vue'), meta: { auth: false,keepAlive:true, breadcrumb: [{ name: '客户功能', route: '' },{ name: '报告转移', route: '' }] } },


      { path: 'money', component: () => import('@/views/Money.vue'), meta: { auth: false, breadcrumb: [{ name: '业务管理', route: '' },{ name: '分成记录', route: '' }] } },
      { path: 'points', component: () => import('@/views/Points.vue'), meta: { auth: false, breadcrumb: [{ name: '业务管理', route: '' },{ name: '积分管理', route: '' }] } },

      { path: 'company',name:'Company', component: () => import('@/views/Company.vue'), meta: { auth: false,keepAlive:true, breadcrumb: [{ name: '业务管理', route: '' },{ name: '公司管理', route: '' }] } },
      { path: 'personnel',name:'Personnel', component: () => import('@/views/Personnel.vue'), meta: { auth: false,keepAlive:true, breadcrumb: [{ name: '业务管理', route: '' },{ name: '客户管理', route: '' }] } },
      { path: 'personnelDetail',name:'PersonnelDetail', component: () => import('@/views/PersonnelDetail.vue'), meta: { auth: false,keepAlive:false, breadcrumb: [{ name: '业务管理', route: '' },{ name: '客户管理', route: '' }] } },



      { path: 'faq', component: () => import('@/views/Faq.vue'), meta: { auth: false, breadcrumb: [{ name: '平台管理', route: '' },{ name: '常见问题', route: '' }] } },
      { path: 'informationManagement',name:"News", component: () => import('@/views/InformationManagement.vue'), meta: { auth: false,keepAlive:true, breadcrumb: [{ name: '平台管理', route: '' },{ name: '资讯管理', route: '' }] } },
      { path: 'informationType', component: () => import('@/views/InformationType.vue'), meta: { auth: false, breadcrumb: [{ name: '平台管理', route: '' },{ name: '咨询类别管理', route: '' }] } },
      { path: 'informationEditor', component: () => import('@/views/InformationEditor.vue'), meta: { auth: false, breadcrumb: [{ name: '平台管理', route: '' },{ name: '咨询编辑', route: '' }] } },
      { path: 'banner', component: () => import('@/views/Banner.vue'), meta: { auth: false, breadcrumb: [{ name: '平台管理', route: '' },{ name: '轮播管理', route: '' }] } },
      { path: 'customerFeedback', component: () => import('@/views/CustomerFeedback.vue'), meta: { auth: false, breadcrumb: [{ name: '平台管理', route: '' },{ name: '客户反馈', route: '' }] } },
      { path: 'config', component: () => import('@/views/Config.vue'), meta: { auth: false, breadcrumb: [{ name: '平台管理', route: '' },{ name: '平台设置', route: '' }] } },


      { path: 'data', component: () => import('@/views/Data.vue'), meta: { auth: false, breadcrumb: [{ name: '系统管理', route: '' },{ name: '基础数据', route: '' }] } },
      { path: 'user', component: () => import('@/views/User.vue'), meta: { auth: false, breadcrumb: [{ name: '系统管理', route: '' },{ name: '人员管理', route: '' }] } }
    ]
  }
];
export default routes;
