import { createRouter, createWebHashHistory } from 'vue-router';
import routes from './routes';
import store from './vuex/store';
import StorageUtils from './utils/StorageUtils';
//创建路由实例，然后传路由配置
const router = new createRouter({
  history: createWebHashHistory(),
  base: process.env.NODE_ENV === 'development' ? '/' : window.globle.BasePath,
  routes
});

window['router'] = router;
/**
 * 验证token是否存在 若存在是否已过期
 * @returns {boolean}
 */
export const tokenExpired = function () {
  let success = false;
  if (StorageUtils.localStorageGet('token') !== null) {
    if (StorageUtils.localStorageGet('expiresTime') !== null) {
      let date = new Date(StorageUtils.localStorageGet('expiresTime'));
      if (date.getTime() - new Date().getTime() > 0) {
        success = true;
      }
    }
  }
  return success;
};

const keepAliveArray = [];

router.beforeEach((to, from, next) => {
  if (to.meta.keepAlive !== null && to.meta.keepAlive) {
    //注意一定要给需要缓存的页面组件都写name属性的值，不是路由Router的name 这里我是默认Router.name=页面name
    let isExist = false;
    for (let i = 0, len = keepAliveArray.length; i < len; i++) {
      let item = keepAliveArray[i];
      if (item === to.name) {
        isExist = true;
        break;
      }
    }
    if (!isExist) {
      keepAliveArray.push(to.name);
      store.storeSet('keepAlive', keepAliveArray);
    }
  }
  store.storeSet('breadcrumb', to.meta.breadcrumb || [], false);
  setTimeout(() => {
    $.zhoujl.resizeLayout();
  }, 100);
  if (to.meta.auth) {
    //是否验证登录
    if (tokenExpired()) {
      //是否已经登录
      next();
    } else {
      //未登录则跳转到登录页面
      if (to.path === '/login') {
        next();
      } else {
        next({
          path: '/login',
          query: { redirect: to.fullPath }
        });
      }
    }
  } else {
    next();
  }
});

export default router;
