import MainHeader from './components/layout/MainHeader';
import ControlSidebar from './components/layout/ControlSidebar';
import MainSidebar from './components/layout/MainSidebar';
import Copyright from './components/layout/Copyright';
import BreadcrumbView from './components/layout/BreadcrumbView';

import InputView from './components/component/InputView';
import SelectView from './components/component/SelectView';
import MultipleSelectView from './components/component/MultipleSelectView';
import DateTimeView from './components/component/DateTimeView';
import RangeDateTimeView from './components/component/RangeDateTimeView';
import PrintView from './components/component/PrintView';
import ImageView from './components/component/ImageView';
import PagerView from './components/component/PagerView';
import ModalView from './components/component/ModalView';
import FileView from './components/component/FileView';
import CheckboxView from './components/component/CheckboxView';
import RadioView from './components/component/RadioView';
import EchartView from './components/component/EchartView';
import RichInputView from './components/component/RichInputView';
import FileUploadView from './components/component/FileUploadView';
import SwitchView from './components/component/SwitchView';

const component = {
  install: function (Vue) {
    Vue.component('main-header', MainHeader);
    Vue.component('control-sidebar', ControlSidebar);
    Vue.component('main-sidebar', MainSidebar);
    Vue.component('copyright', Copyright);
    Vue.component('breadcrumb-view', BreadcrumbView);

    Vue.component('input-view', InputView);
    Vue.component('select-view', SelectView);
    Vue.component('multiple-select-view', MultipleSelectView);
    Vue.component('date-time-view', DateTimeView);
    Vue.component('range-date-time-view', RangeDateTimeView);
    Vue.component('print-view', PrintView);
    Vue.component('image-view', ImageView);
    Vue.component('pager-view', PagerView);
    Vue.component('modal-view', ModalView);
    Vue.component('file-view', FileView);
    Vue.component('checkbox-view', CheckboxView);
    Vue.component('radio-view', RadioView);
    Vue.component('echart-view', EchartView);
    Vue.component('rich-input-view', RichInputView);
    Vue.component('file-upload-view', FileUploadView);
    Vue.component('switch-view', SwitchView);
  }
};
export default component;
